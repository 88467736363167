img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.main-content{
  /* padding: 20px; */
  box-shadow: inset 0 0 0 1000px rgba(221, 208, 200);
}
.hero-container {
  background: url('../../public/images/fingerprintDemo.jpeg') center center/cover no-repeat;
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: contain;
}

.hero-container1{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.hero-container > h1 {
  color: #fff;
  font-size: 64px;
  margin-top: -100px;
  font-weight: 800;
  width: 55%;
  line-height: 75px;
}

.hero-container > p {
  /* margin-top: 8px; */
  font-size: 16px;
  padding-top: 30px;
  width: 47%;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.left{
  width: 48%;
  float: left;
}
.right{
  float: right;
  width: 48%;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 64px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 24px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 16px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
