.body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .header {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-bottom: 2px solid #ddd;
  }
  
  .header h1 {
    color: #000;
  }
  
  .header h2 {
    color: #000;
    margin-top: 10px;
  }
  
  .section {
    margin: 40px 0;
  }
  
  .intro, .content, .advantages, .why-choose-us, .cta {
    margin-bottom: 20px;
  }
  
  h3 {
    color: #0073e6;
    margin-bottom: 10px;
  }
  
  .image {
    width: 100%;
    max-width: 600px;
    margin: 20px 0;
    display: block;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul li {
    margin: 10px 0;
    line-height: 1.5;
  }
  
  .footer {
    text-align: center;
    margin-top: 40px;
    padding: 20px;
    background-color: #f8f8f8;
    border-top: 2px solid #ddd;
  }
  