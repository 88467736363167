.hero-container-contact {
  position: relative;
  background: url('../../public/images/contactus.jpg') center center/cover no-repeat;
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  padding-bottom: 10%;
  }
  
  .heading-para-contact p {
    color: #fff;;
    font-size: 32px; 
    font-weight: 800;
    line-height: 28px;
    text-align: left;
    margin: 0;
    padding-left: 20px; 
    padding-bottom: 10px;
  }
  
  
  
  @media screen and (max-width: 960px) {
    .about p {
      font-size: 22px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .about p {
      font-size: 18px;
    }
  }
  
  
  
  
  @media screen and (max-width: 960px) {
    .heading-text-contact p {
      font-size: 22px; /* Further decreased font size */
    }
  
    .heading-para-contact p {
      font-size: 26px; /* Further decreased font size */
    }
  }
  
  @media screen and (max-width: 768px) {
    .heading-text-contact p {
      font-size: 18px; /* Further decreased font size */
    }
  
    .heading-para-contact p {
      font-size: 22px; /* Further increased font size */
    }
  }
  