.cards {
  padding: 2rem;
  background: #fff;
}

h1 {
  text-align: center;
  padding: 10px;
  font-size: 34px;
  font-weight: 600;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
}

.cards__items {
  /* margin-bottom: 24px; */
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  /* width: 100%; */
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  /* -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017)); */
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  /* position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden; */
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__img {
  /* position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; */
  display: block;
  width: 45%;
  margin-left: 25%;
  padding: 10%;
  /* max-width: 100%;
  height: 100%;
  max-height: 50%; */
  /* object-fit: cover;
  transition: all 0.2s linear; */
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__title {
  color: #000000;
  font-size: 24px;
  font-weight: 400;
  padding-bottom: 10px;
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.cards__item__info {
  padding: 0px 30px 20px;
  text-align: center;
}

.cards__item__text {
  color: black;
  font-size: 14px;
}

.documents {
  background: url('../../public/images/doc.jpg') center center/cover no-repeat;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  object-fit: contain;
  padding: 1rem;
  margin: 1% 0%;
}
.documents h1{
  padding: 20px;
  font-size: 40px;
  font-weight: 700;
  /* text-shadow: 2px 2px 5px #00000066; */
}

.service{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-shadow: inset 0 0 0 1000px rgb(255, 255, 255);
}

.service h1{
  padding: 20px;
  font-size: 40px;
  font-weight: 700;
  /* text-shadow: 2px 2px 5px #00000066; */
}

.service h4{
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.5em;
  padding-bottom: 1rem;
}

.service p{
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  padding-bottom: 10px;
  color: #000;
}
.service a {
  display: flex;
  /* flex-flow: column; */
  overflow: hidden;
  text-decoration: none;
  color: #000;
}
.Grid{
  display: flex;
  justify-content: space-between;
  object-fit: contain;
  width: 80%;
  margin: auto;
  position: relative;
  padding: 20px;
}
.left{
  width: 48%;
  float: left;
}
.right{
  float: right;
  width: 48%;
}
.inner-content{
  box-shadow: 2px 2px 16px 0px rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  padding: 20px;
}
.about1{
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgb(255, 255, 255);
  object-fit: contain;
  padding: 2rem;
}

.customers{
  max-width: 1080px;
  margin: auto;
  background: #fff;
  padding: 1rem;
}

.customers p{
  line-height: 1.5rem;
  font-size: 1rem;
  font-weight: 400;
  color: #000;
}

.customers .list{
  margin: auto;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #000;
}
.customers .col-0,.col-1, .col-2{
  padding: 1rem;
  line-height: 1.5;
}

.Faq{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  box-shadow: inset 0 0 0 1000px rgb(255, 255, 255);
}
.Faq h1{
  padding: 20px;
  font-size: 40px;
  font-weight: 700;
  /* text-shadow: 2px 2px 5px #00000066; */
}
.Faq p{
  line-height: 1.5rem;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
}
.accordion{
  padding: 2rem;
  width: 60%;
  max-width: 800px; 
  margin: auto;
}
.accordion_item{
  margin-bottom: 10px;
  box-shadow: 0px 0px 4px 0px #00000066;
  border-radius: 10px;
}

.accordion_item #panel2-header{
  background-color: skyblue;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 820px) {
  .cards__item {
    margin-bottom: 2rem;
  }
  .Grid{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .service h1 {
    font-size: 32px; 
  }

  .service h4 {
    font-size: 1.2rem; 
  }

  .service p {
    font-size: 0.9rem;
  }

  .left,
  .right {
    width: 100% !important; 
    float: none; 
  }

  .Faq p {
    font-size: 14px; 
  }

  .accordion {
    padding: 1rem;
    width: 100%;
  }
  

}
