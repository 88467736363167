.notary-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
  }
  
  .header {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .services, .benefits, .faq {
    margin: 20px 0;
  }
  
  .services ul, .benefits ul {
    list-style: disc;
    margin: 0;
    padding: 0 20px;
  }
  
  .accordion_item {
    margin: 10px 0;
  }
  
  .footer {
    text-align: center;
    background-color: #333;
    color: white;
    padding: 20px;
    border-radius: 8px;
  }
  
  .footer h2 {
    margin: 0 0 10px;
  }
  
  @media (min-width: 768px) {
    .Grid {
      display: flex;
      justify-content: space-between;
    }
  
    .left img {
      max-width: 100%;
      height: auto;
      margin-right: 20px;
    }
  }
  