* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  /* overflow-x: hidden; */
}

.home,
.services {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('../public/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff; */
  font-size: 100px;
}

.aboutus .head {
  /* background-image: url('../public/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff; */
  /* font-size: 100px; */
  background: #fff
}

.aboutus .head h1{
  text-align: center;
  padding: 10px;
  font-size: 40px;
  font-weight: 700;
  /* text-shadow: 2px 2px 5px #00000066; */
}
.aboutus .content{
  width: 80%;
  margin: auto;
  max-width: 1080px;
  padding: 5% 0%;

  h2{
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 10px;
  }
  p{
    padding-bottom: 2em;
    font-size: 16px;
    line-height: 2em;
    color: #666;
  }
}

.accounts{
  width: 80%;
  margin: auto;
}

.account-text {
  /* background-image: url('../public/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px; */
  padding: 1rem;
}

.footer {
  text-align: center;
  background-color: #333;
  color: white;
  padding: 20px;
  margin-left: 240px;
  margin-right: 240px;
  margin-bottom: 12px;
  border-radius: 8px;
}

.footer h2 {
  margin: 0 0 10px;
}
@media only screen and (max-width: 768px) {
  .aboutus .content {
    width: 90%; 
    padding: 5% 2%; 
  }

  .aboutus .head h1 {
    font-size: 32px; 
  }

  .aboutus .content h2 {
    font-size: 16px; 
  }

  .aboutus .content p {
    font-size: 12px; 
  }

  h1 {
    font-size: 24px !important; 
  }

  .accounts {
    width: 90%;
  }

  .account-text {
    padding: 0.5rem; 

    h4{
      font-size: 12px !important;
    }
    p{
      font-size: 12px !important;
    }
  }


}