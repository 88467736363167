.footer-container {
  background-color: #242424;
  padding: 0.5rem 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-links {
  width: 100%;
  max-width: 1000px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.footer-link-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.footer-link-items {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  text-align: left;
  /* width: 160px; */
  box-sizing: border-box;
  padding: 15px;
  color: #fff;
  max-width: 310px;
}

.footer-link-items a {
  color: #fff;
  text-decoration: none;
  padding: 5px;
}

.footer-link-items a:hover {
  color: #e9e9e9;
  transition: 0.3s ease-out;
}

.footer-link-icons{
  display: flex;
  gap: 20px;
  align-items: center;
}
.footer-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: #535455;
  align-items: center;
  padding: 11px;
  width: 100%;
}

.footer-text .copyrights {
  padding-right: 22px;
  font-size: 16px;
  padding-bottom: 0 !important;
  font-weight: 400;
  color: #EFEFEF;
}


@media screen and (max-width: 820px) {
  .footer-links {
    padding-top: 2rem;
  }
  .footer-link-items {
    max-width: calc(50% - 32px); 
  }
  .footer-input {
    width: 100%;
  }

  .btn {
    width: 100%;
  }

  .footer-link-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }

}

@media screen and (max-width: 480px) {
  .footer-link-items {
    max-width: calc(100% - 32px);
  }

  .footer-link-wrapper {
    justify-content: center; 
  }
  .footer-text .copyrights {
    font-size: 12px !important;
  }
}