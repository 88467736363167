.hero-container-services {
  position: relative;
  height: 60vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-bottom: 10%;
  color:black
}

.heading-text p {
  color: #000;
  font-size: 24px; 
  font-weight: 100;
  line-height: 30px; 
  margin-bottom: 30px;
  padding-left: 20px; 
}

.heading-para p {
  color: #000;;
  font-size: 32px; 
  font-weight: 800;
  line-height: 28px; 
  text-align: left;
  margin: 0;
  padding-left: 20px; 
  padding-bottom: 10px;
}

.about {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center align items horizontally */
  box-shadow: inset 0 0 0 1000px rgba(124, 123, 123, 0.2);
  object-fit: contain;
  padding: 2rem;
}


@media screen and (max-width: 960px) {
  .about p {
    font-size: 22px;
  }
}

@media screen and (max-width: 768px) {
  .about p {
    font-size: 18px;
  }
}




@media screen and (max-width: 960px) {
  .heading-text p {
    font-size: 22px; 
  }

  .heading-para p {
    font-size: 26px; 
  }
}

@media screen and (max-width: 768px) {
  .heading-text p {
    font-size: 18px; 
  }

  .heading-para p {
    font-size: 22px; 
  }
}
