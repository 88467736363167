.apostille-container {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin: 0 auto;
    padding: 20px;
    max-width: 1200px;
  }
  
  .header {
    text-align: center;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .steps {
    margin: 20px 0;
  }
  
  .steps .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
    text-align: center;
  }
  
  .steps img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 10px;
  }
  
  .why-choose-us, .additional-services, .faq {
    margin: 20px 0;
  }
  
  .why-choose-us ul {
    list-style: disc;
    margin: 0;
    padding: 0 20px;
  }
  
  .footer {
    text-align: center;
    background-color: #333;
    color: white;
    padding: 20px;
    border-radius: 8px;
  }
  
  .footer h2 {
    margin: 0 0 10px;
  }
  
  @media (min-width: 768px) {
    .steps .step {
      flex-direction: row;
      text-align: left;
    }
  
    .steps img {
      width: 40%;
      margin-right: 20px;
    }
  }
  